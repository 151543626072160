import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import { ROLES } from "utils/constants";

const { superAdmin, dataEntry, operation } = ROLES;

export default new Entity({
  name: "promotion",
  pluralName: "promotions",
  pathName: "promotions",
  endpoint: "/admin/promotions",
  serverName: "promotion",
  serverPluralName: "promotions",
  displayName: { en: "Promotions", ar: "العروض" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin],
    create: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "code",
      label: { en: "Code", ar: "الكود" },
      accessor: "code",
      inList: true,
      usedToShow: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Name Arabic", ar: "الاسم عربي" },
      accessor: "name_ar",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "name_en",
      label: { en: "Name English", ar: "الاسم انجليزي" },
      accessor: "name_en",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "has_dedicated_page",
      label: { en: "Has dedicated page", ar: "له صفحة مخصصة" },
      inList: true,
      accessor: (dataObject) => (dataObject.has_dedicated_page ? "Yes" : "No"),
      formAccessor: (dataObject) => (dataObject.has_dedicated_page ? "true" : "false"),
      type: "radio",
      options: [
        { value: "true", label: { en: "Yes", ar: "Yes" } },
        { value: "false", label: { en: "No", ar: "No" } },
      ],
      required: true,
    },
    {
      identifier: "slug",
      label: "Slug",
      accessor: "slug",
      type: "string",
    },
    {
      identifier: "start_date",
      label: { en: "Start date", ar: "تاريخ البدء" },
      accessor: (dataObject) => (dataObject.start_date ? moment(dataObject.start_date).format("MMMM Do YYYY h:mm a") : null),
      formAccessor: "start_date",
      inList: true,
      type: "datetime",
      disablePast: true,
      required: true,
    },
    {
      identifier: "end_date",
      label: { en: "End date", ar: "تاريخ الانتهاء" },
      accessor: (dataObject) => (dataObject.end_date ? moment(dataObject.end_date).format("MMMM Do YYYY h:mm a") : null),
      formAccessor: "end_date",
      inList: true,
      type: "datetime",
      required: true,
    },
    {
      identifier: "promotion_groups",
      accessor: "promotion_groups",
      label: { en: "Products", ar: "عناصر الطلب" },
      type: "dynamic",
      fields: [
        {
          identifier: "percentage",
          accessor: "percentage",
          type: "number",
          label: { en: "Percentage", ar: "الكمية" },
          required: true,
        },
        {
          identifier: "product_ids",
          label: { en: "Products", ar: "المنتجات" },
          lgCol: 9,
          mdCol: 9,
          accessor: (dataObject) => (dataObject.products ? dataObject.products : []),
          type: "reference",
          multiple: true,
          isSearchable: true,
          required: true,
          optionsEntity: {
            name: "product",
            labelField: "title_en",
            valueField: "id",
            filterParams: [
              {
                name: "without_effective_promotion",
                value: "true",
                type: "both",
              },
            ],
          },
        },
      ],
    },
  ],
  filters: [
    {
      label: { en: "Start date", ar: "منذ" },
      name: "since",
      type: "date",
    },
    {
      label: { en: "End date", ar: "حتى" },
      name: "until",
      type: "date",
    },
    {
      label: { en: "Has dedicated page", ar: "في صفحة مخصصة" },
      name: "dedicated_page",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
    },
  ],
});
