import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import SplitTextByNewLine from "../../components/SplitTextByNewLine";
import { ROLES } from "utils/constants";
const { superAdmin } = ROLES;
export default new Entity({
  name: "orderVersion",
  pluralName: "orderVersions",
  pathName: "orderVersion",
  endpoint: "/admin/orders/versions",
  serverName: "paper_trail/version",
  serverPluralName: "paper_trail/versions",
  displayName: { en: "Order Versions", ar: "معاينة التعديلات على المنتج" },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  authorizedRoles: {
    entity: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "مسلسل" },
      accessor: "id",
      usedToShow: true,
    },
    {
      identifier: "item_id",
      label: { en: "Order id", ar: "رقم الطلب" },
      accessor: "item_id",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "event",
      label: { en: "Event", ar: "الحالة" },
      accessor: "event",
      inList: true,
    },
    {
      identifier: "whodunnit",
      label: { en: "Created/Updated by", ar: "تم إنشاؤه/تحديثه بواسطة" },
      accessor: "whodunnit",
      inList: true,
    },
    {
      identifier: "created_at_date",
      label: { en: "Created/Updated at Date", ar: "تم إنشاؤه/تحديثه في التاريخ" },
      accessor: (dataObject) => (dataObject?.created_at ? moment(dataObject.created_at).format("lll") : null),
      inList: true,
    },
    {
      identifier: "checkout_total_price",
      label: { en: "Total Price", ar: "سعر" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.checkout_total_price,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.checkout_total_price : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "rating",
      label: { en: "Rating", ar: "تقييم" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.rating,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.rating : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "checkout_date",
      label: { en: "Checkout Date", ar: "موعد انتهاء الأقامة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.checkout_date,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.checkout_date : null),
      inList: false,
      type: "Date",
      component: SplitTextByNewLine,
    },
    {
      identifier: "bank_installment_id",
      label: { en: "Bank Installment Id", ar: "معرف الدفعةالمصرفية" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.bank_installment_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.bank_installment_id : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "delivered_date",
      label: { en: "Delivered Date", ar: "تاريخ التسليم" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.delivered_date,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.delivered_date : null),
      inList: false,
      type: "date",
      component: SplitTextByNewLine,
    },
    {
      identifier: "admin_id",
      label: { en: "Admin Id", ar: "معرف المسؤول" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.admin_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.admin_id : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "refund_reason",
      label: { en: "Refund Reason", ar: "سبب الأسترداد" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.refund_reason,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.refund_reason : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.status,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.status : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "address_id",
      label: { en: "Address id", ar: "معرف العنوان" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.address_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.address_id : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "user_id",
      label: { en: "User id", ar: "معرف المستخدم" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.user_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.user_id : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "sync_status",
      label: { en: "Sync Status", ar: "حالة المزامنة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.sync_status,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.sync_status : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "sync_error_message",
      label: { en: "Sync Error", ar: "خطأ مزامنة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.sync_error,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.sync_error : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
  ],
});
