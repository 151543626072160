import { rtlLanguages, resources } from "translation/i18n";
import logo from "assets/logo.png";
import landing from "assets/landing.jpg";
import fullLogo from "assets/fulllogo.png";
import { API_BASE_URL } from "utils/constants";
import { groups, userDefinedEntities } from "./entities";
import { authConfig, authUserDefinedEntities } from "authEntities";
import { customTheme } from "components/Theme";
import { store } from "./index";
import rootReducer from "./reducers";
import { history } from "configureStore";
import dashboardConfig from "dashboard";
import playNotificationSound from "utils/playNotificationSound";

const storeDispatcher = type => {
  store.dispatch({ type });
};

const replaceReducer = adminPanelReducers => {
  const nextReducer = rootReducer(history, adminPanelReducers);
  store.replaceReducer(nextReducer);
};

const {
  REACT_APP_FIREBASE_KEY = "AIzaSyD3Wh5ZS6hQz80QN4Qo_DwWMpzrrFZUFIc",
  REACT_APP_AUTH_DOMAIN = "abdelaziz-stores.firebaseapp.com",
  REACT_APP_PROJECT_ID = "abdelaziz-stores",
  REACT_APP_STORAGE_BUCKET = "abdelaziz-stores.appspot.com",
  REACT_APP_SENDER_ID = 968379516985,
  REACT_APP_FIREBASE_APP_ID = "1:968379516985:web:b3423f84ba1b83bdffa5e8",
  REACT_APP_FIREBASE_VAPID_KEY = "BCJ8WDKlRlezytBt-BVQJ5o0BRir6f5yVbIxWA3wpdoMC7Exo4lKrDymi-HwjWZ3PvPmGZXSV1xCJZf0HqH-9jM",
} = process.env;

const notificationConfigs = {
  enabled: true,
  baseURL: `${API_BASE_URL}/admin`,
  authHeaders: "authHeaders",
  displayAsLink: notification => `/admin/orders/${notification.data_object.order.id}`,
  onReceiveNotification: playNotificationSound,
  firebaseConfig: {
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
  },
  theme: {
    notificationLayout: {
      dropDownAnimation: "opacity",
      displayImages: true,
      iconSize: 2,
      headerHeight: 80,
    },
    notificationColors: {
      date: "#505050",
      icon: "#000",
      iconHover: "#1e4b9b",
      scrollBarThumb: "#505050",
      scrollBarTrack: "#f5f5f5",
      unreadNotification: "#40a9ff",
      background: "#fff",
    },
  },
};

export const adminConfigs = {
  languageResources: resources,
  rtlLanguages,
  logo,
  landingImage: landing,
  fullLogo,
  url: API_BASE_URL,
  groups,
  userDefinedEntities,
  authUserDefinedEntities,
  authConfig,
  theme: customTheme,
  privateRouteURL: "/admin",
  storeDispatcher,
  dashboardConfig,
  replaceReducer,
  notificationConfigs,
};
