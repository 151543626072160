import { Entity } from "@thewebops/admin-panel";

export default authConfig =>
  new Entity({
    title: `Complete your ${authConfig.displayName} profile`,
    name: "completeProfile",
    subTitle: `Enter the credentials associated with your ${authConfig.displayName} account.`,
    path: "/confirmations/:token",
    actionLabel: "Complete my profile",
    action: "create",
    // hideFromNavbar: true,
    // preventAccess: auth => false,
    fields: [
      {
        identifier: "name",
        label: { en: "Name", ar: "الاسم" },
        accessor: "name",
        type: "string",
      },
      {
        label: "New Password",
        required: true,
        type: "password",
        identifier: "password",
      },
      {
        label: "Confirm new Password",
        required: true,
        type: "password",
        identifier: "password_confirmation",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverName}/confirmations`,
      method: "put",
      successMessage: "Profile completed",
      errorMessage: "Couldn't complete profile",
      payloadWrapper: {
        name: "admin",
        fields: ["name", "password", "password_confirmation"],
      },
      successRedirectPath: "/login",
      useFormData: true,
    },
    queryParamName: "confirmation_token",
  });
