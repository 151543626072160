import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "address",
  pluralName: "addresses",
  pathName: "addresses",
  endpoint: "/admin/addresses",
  serverName: "address",
  serverPluralName: "addresses",
  isPassive: true,
  displayName: { en: "Addresses", ar: "العناوين" },
  entityIdentifier: "id",
});
