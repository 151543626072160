import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";
import { isAdminRole } from "utils/authHelpers";
import { push } from "connected-react-router";
import moment from "moment";
import actions from "./redux/actions";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import statusesSection from "./sections/statuses";

const { superAdmin, operation, callCenter } = ROLES;
const canAssignBankInstallmentPlan = () => isAdminRole("super_admin") || isAdminRole("call_center");

export default new Entity({
  name: "order",
  pluralName: "orders",
  pathName: "orders",
  endpoint: "/admin/orders",
  serverName: "order",
  serverPluralName: "orders",
  displayName: { en: "Orders", ar: "الطلبات" },
  showFiltersInDrawer: true,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  subscribeTo: ["create", "edit", "delete"],
  authorizedRoles: {
    entity: [superAdmin, operation, callCenter],
    edit: [superAdmin, callCenter, operation],
  },
  globalActions: [
    {
      label: { en: "History", ar: "التاريخ" },
      name: "showVersions",
      customFunction: (dispatch, item) => {
        dispatch(push("orderVersion"));
      },
    },
    {
      label: { en: "Export", ar: "تحميل" },
      name: "exportOrders",
    },
  ],
  rowActions: [
    {
      label: { en: "Resubmit E-Invoice", ar: "إعادة تقديم الفاتورة الإلكترونية" },
      name: "resubmitEInvoice",
      active: (dataObject) => dataObject?.e_invoice_status == "failed",
    },
    {
      label: { en: "Retry SAP sync", ar: "اعادة SAP المزامنة" },
      name: "retrySAPSync",
      active: (dataObject) => dataObject?.sync_status == "failed",
    },
    {
      label: "Item Invoice",
      active: (dataObject) => (dataObject?.item_invoice_pdf_url ? true : false),
      customFunction: (dispatch, item) => {
        const win = window.open(item?.item_invoice_pdf_url, "_blank");
        win.focus();
      },
    },
    {
      label: "Delivery Invoice",
      active: (dataObject) => (dataObject?.delivery_invoice_pdf_url ? true : false),
      customFunction: (dispatch, item) => {
        const win = window.open(item?.delivery_invoice_pdf_url, "_blank");
        win.focus();
      },
    },
    {
      label: "Refund Invoice",
      active: (dataObject) => (dataObject?.refund_invoice_pdf_url ? true : false),
      customFunction: (dispatch, item) => {
        const win = window.open(item?.refund_invoice_pdf_url, "_blank");
        win.focus();
      },
    },
    {
      label: "Release Order",
      active: (dataObject) => (dataObject?.release_order_pdf_url ? true : false),
      customFunction: (dispatch, item) => {
        const win = window.open(item?.release_order_pdf_url, "_blank");
        win.focus();
      },
    },
  ],
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "user_id",
      label: { en: "User", ar: "المستخدم" },
      accessor: (dataObject) => (dataObject?.user ? dataObject.user.full_name : null),
      formAccessor: (dataObject) => (dataObject?.user ? dataObject.user.id : null),
      inList: true,
      hideInEdit: true,
      type: "reference",
      isSearchable: true,
      optionsEntity: {
        name: "user",
        labelField: "full_name",
        valueField: "id",
      },
      disabled: true,
    },
    {
      identifier: "order_transaction_id",
      label: { en: "Transaction ID", ar: "رقم العملية" },
      accessor: (dataObject) => (dataObject?.order_transaction ? dataObject.order_transaction.id : "-"),
      formAccessor: (dataObject) => (dataObject?.order_transaction ? dataObject.order_transaction.id : null),
      type: "reference",
      hideInEdit: true,
      inList: true,
      optionsEntity: {
        name: "transaction",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
    },
    {
      identifier: "refund_transaction_id",
      label: { en: "Refund Transaction ID", ar: "رقم  مرتجع العملية" },
      accessor: (dataObject) => (dataObject?.refund_transaction ? dataObject.refund_transaction.id : "-"),
      formAccessor: (dataObject) => (dataObject?.refund_transaction ? dataObject.refund_transaction.id : null),
      type: "reference",
      hideInEdit: true,
      inList: true,
      optionsEntity: {
        name: "transaction",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
    },
    {
      identifier: "phone_number",
      hideInEdit: true,
      accessor: (dataObject) => (dataObject?.user?.phone_number: "-"),
      label: { en: "User Phone Number", ar: "رقم الهاتف" },
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      accessor: "status",
      disabled: true,
      inList: true,
      type: "select",
      options: [
        { value: "canceled", label: { en: "Canceled", ar: "ملغى" } },
        { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
        { value: "confirmed", label: { en: "Confirmed", ar: "تم التأكيد" } },
        { value: "delivering", label: { en: "Delivering", ar: "في الطريق" } },
        { value: "delivered", label: { en: "Delivered", ar: "تم التوصيل" } },
        { value: "refunded", label: { en: "Refunded", ar: "تم الاسترجاع" } },
        { value: "partially_refunded", label: { en: "Partially refunded", ar: "مرتجع جزئياً" } },
        { value: "processing", label: { en: "Processing", ar: "جاري التجهيز" } },
      ],

      required: true,
    },
    ...statusesSection,
    {
      identifier: "payment_method",
      label: { en: "Payment method", ar: "طريقة الدفع" },
      accessor: (dataObject) => dataObject?.payment_method,
      hideInEdit: (dataObject) => !canAssignBankInstallmentPlan() || dataObject?.payment_method === "online",
      type: "select",
      required: true,
      inList: true,
      useHumanizer: true,
      disabled: (action, dataObject) =>
        action === "edit" && ["canceled", "delivered", "refunded", "partially_refunded"].includes(dataObject?.status),
      options: [
        { value: "cash", label: { en: "Cash", ar: "كاش" } },
        { value: "visa_on_delivery", label: { en: "Card on delivery", ar: "كارت عند الاستلام" } },
        {
          value: "visa_on_delivery_installments",
          label: { en: "Card on delivery - installments", ar: "كارت عند الاستلام - تقسيط" },
        },
      ],
    },
    {
      identifier: "rating",
      label: { en: "Rating", ar: "التقييم" },
      accessor: (dataObject) => dataObject?.rating || "-",
      type: "number",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "bank_installment_id",
      label: { en: "Bank Installment", ar: "الأقساط البنكية" },
      accessor: (dataObject) => dataObject?.bank_installment?.title,
      formAccessor: (dataObject) => dataObject?.bank_installment?.id,
      hideInEdit: false,
      hideInShow: true,
      isSearchable: true,
      type: "reference",
      dependsOn: "payment_method",
      dependsOnValue: "visa_on_delivery_installments",
      optionsEntity: {
        name: "bankInstallment",
        labelField: "title",
        valueField: "id",
      },
    },
    {
      identifier: "order_products_attributes",
      accessor: "order_products",
      label: { en: "Order Items", ar: "عناصر الطلب" },
      type: "dynamic",
      dependeeListOperator: "or",
      dependeeList: [
        {
          dependsOn: "status_event",
          hideOnValue: ["deliver", "mark_as_delivered", "cancel", "refund", "partial_refund"],
        },
        {
          dependsOn: "status",
          hideOnValue: ["delivering", "delivered", "canceled", "refunded", "partially_refunded"],
        },
      ],
      hideInEdit: (dataObject) =>
        !isAdminRole("super_admin") ||
        dataObject?.payment_method === "online" ||
        ["partially_refunded", "refunded"].includes(dataObject?.status),
      fields: [
        {
          identifier: "id",
          label: { en: "ID", ar: "مسلسل" },
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "product_id",
          label: { en: "Product", ar: "المنتج" },
          accessor: (dataObject) =>
            dataObject?.product?.description
              ? `${dataObject?.product?.description} - ${dataObject?.product?.stock}`
              : null,
          formAccessor: (dataObject) => (dataObject?.product ? dataObject?.product?.id : null),
          type: "reference",
          isSearchable: true,
          optionsEntity: {
            name: "product",
            labelField: (dataObject) => `${dataObject?.description} - ${dataObject?.stock}`,
            valueField: "id",
          },
        },
        {
          identifier: "quantity",
          accessor: "quantity",
          type: "number",
          label: { en: "Quantity", ar: "الكمية" },
        },
      ],
    },
    {
      identifier: "refunded_order_products_attributes",
      label: { en: "Refunded products", ar: "المنتجات المستردة" },
      type: "table",
      accessor: (data) => data?.order_products || [],
      hideInCreate: true,
      hideInEdit: false,
      hideInShow: true,
      lgCol: 12,
      mdCol: 12,
      persistDependeeValues: true,
      dependsOn: "status_event",
      dependsOnValue: "partial_refund",
      tableConfig: {
        name: "orderProduct",
        type: "static",
        editable: true,
        editableFields: ["refunded_quantity"],
      },
    },
    {
      identifier: "refunded_order_products",
      label: { en: "Order products", ar: "المنتجات المستردة" },
      type: "table",
      accessor: (data) => data?.order_products || [],
      hideInCreate: true,
      hideInEdit: true,
      lgCol: 12,
      mdCol: 12,
      hideInShow: (dataObject) => !["partially_refunded", "refunded"].includes(dataObject?.status),
      tableConfig: {
        name: "orderProduct",
        type: "static",
      },
    },
    {
      identifier: "address_show",
      accessor: (dataObject) => dataObject?.address ? [dataObject.address] : [],
      label: { en: "Address", ar: "العنوان" },
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "name",
          accessor: "name",
          label: { en: "Name", ar: "الاسم" },
        },
        {
          identifier: "phone_number",
          accessor: "phone_number",
          label: { en: "Phone Number", ar: "رقم الهاتف" },
        },
        {
          identifier: "number_type",
          label: { en: "Number Type", ar: "نوع الرقم" },
          accessor: "number_type",
        },
        {
          identifier: "city_code",
          label: { en: "City Code", ar: "كود المحافظة" },
          accessor: "city_code",
        },
        {
          identifier: "street_name",
          label: { en: "Street Name", ar: "اسم الشارع" },
          accessor: "street_name",
        },
        {
          identifier: "apartment",
          label: { en: "Apartment Number", ar: "رقم الشقة" },
          accessor: "apartment",
        },

        {
          identifier: "floor",
          label: { en: "Floor Number", ar: "الدور" },
          accessor: "floor",
        },

        {
          identifier: "building",
          label: { en: "Building Number", ar: "رقم العمارة" },
          accessor: "building",
        },
        {
          identifier: "district",
          accessor: "district",
          label: { en: "District", ar: "حي" },
        },
        {
          identifier: "governorate",
          accessor: "governorate",
          label: { en: "Governorate", ar: "المحافظة" },
        },
      ],
    },
    {
      identifier: "order_products",
      accessor: (data) => data?.order_products || [],
      label: { en: "Products Ordered", ar: "طلبات العميل" },
      type: "table",
      hideInCreate: true,
      hideInEdit: true,
      tableConfig: {
        name: "orderProduct",
        // staticDataAccessor: "product",
        import: {
          enabled: false,
        },
      },
    },
    {
      identifier: "created_at",
      label: { en: "Order date", ar: "تاريخ الطلب" },
      accessor: (dataObject) =>
        dataObject?.checkout_date ? moment(dataObject?.checkout_date).format("DD/MM/YYYY h:mm A") : null,
      type: "datetime",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "shipping_fees",
      label: { en: "Shipping Fees", ar: "مصاريف الشحن" },
      accessor: "checkout_shipping_fees",
      inList: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "total_price",
      label: { en: "Total Price", ar: "السعر الكلي" },
      accessor: "checkout_total_price",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "bank_installment_show",
      accessor: (dataObject) => (dataObject?.bank_installment ? [dataObject?.bank_installment] : null),
      hideInShow: (dataObject) => dataObject?.payment_method !== "visa_on_delivery_installments",
      label: { en: "Bank Installment", ar: "الأقساط البنكية" },
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "title",
          accessor: "title",
          label: { en: "Title", ar: "العنوان" },
        },
        {
          identifier: "months",
          label: { en: "Months", ar: "عدد الشهور" },
          accessor: "months",
        },
      ],
    },
    {
      identifier: "sync_status",
      label: { en: "Sync Status", ar: "حالة المزامنة" },
      accessor: "sync_status",
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      type: "select",
      options: [
        { value: "success", label: { en: "Success", ar: "تم المزامنة" } },
        { value: "failed", label: { en: "Failed", ar: "فشلت المزامنة" } },
        {
          value: "pending",
          label: { en: "Pending", ar: "بإنتظار المزامنة" },
        },
      ],
    },
    {
      identifier: "sync_error_message",
      label: { en: "SAP Sync Error Message", ar: "SAP Sync Error Message" },
      accessor: (dataObject) => dataObject?.sync_error_message || "-",
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "e_invoice_status",
      label: { en: "E-Invoice Status", ar: "حالة الفاتورة الإلكترونية" },
      accessor: (dataObject) => dataObject?.e_invoice_status?.replace(/_/g, " "),
      inList: true,
      hideInCreate: true,
      hideInEdit: true,
      type: "string",
    },
    {
      identifier: "e_invoice_errors",
      label: { en: "E-Invoice Submission Errors", ar: "أخطاء تقديم الفاتورة الإلكترونية" },
      accessor: "e_invoice_errors",
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "eta_sales_receipt_number",
      label: { en: "ETA Sales Receipt Number", ar: "رقم الفاتورة الضريبية للمبيعات" },
      accessor: "eta_sales_receipt_number",
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "eta_refund_receipt_number",
      label: { en: "ETA Refund Receipt Number", ar: "رقم الفاتورة الضريبية الملغاة" },
      accessor: "eta_refund_receipt_number",
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
  ],
  filters: [
    {
      label: { en: "Status", ar: "الحالة" },
      name: "status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
          { value: "confirmed", label: { en: "Confirmed", ar: "تم التأكيد" } },
          { value: "canceled", label: { en: "Canceled", ar: "ملغى" } },
          { value: "processing", label: { en: "Processing", ar: "يتم المعالجة" } },
          { value: "delivering", label: { en: "Delivering", ar: "في الطريق" } },
          { value: "delivered", label: { en: "Delivered", ar: "تم التوصيل" } },
          { value: "partially_refunded", label: { en: "Partially Refunded", ar: "المردودة جزئيا" } },
          { value: "refunded", label: { en: "Refunded", ar: "تم الاسترجاع" } },
        ],
      },
    },
    {
      label: { en: "Payment method", ar: "طريقة الدفع" },
      name: "payment_method",
      type: "select",
      dependee: true,
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "cash", label: { en: "Cash", ar: "كاش" } },
          { value: "online", label: { en: "Online", ar: "اونلاين" } },
          { value: "visa_on_delivery", label: { en: "Card on delivery", ar: "كارت عند الاستلام" } },
          {
            value: "visa_on_delivery_installments",
            label: { en: "Card on delivery - installments", ar: "كارت عند الاستلام - تقسيط" },
          },
        ],
      },
    },
    {
      label: { en: "Installements", ar: "الأقساط البنكية" },
      name: "bank_installment_id",
      dependsOn: "payment_method",
      dependsOnValue: "visa_on_delivery_installments",
      type: "select",
      hideInEdit: (dataObject) => !canAssignBankInstallmentPlan(),
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "bankInstallment",
          labelField: "title_en",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "User", ar: "المستخدم" },
      name: "user_id",
      type: "select",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "user",
          labelField: "first_name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Start Time", ar: "منذ" },
      name: "created_at_since",
      type: "date",
    },
    {
      label: { en: "End Time", ar: "حتى" },
      name: "created_at_until",
      type: "date",
    },
    {
      label: { en: "Sync Status", ar: "حالة المزامنة" },
      name: "sync_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "pending", label: { en: "Pending Sync", ar: "بانتظار المزامنة" } },
          { value: "failed", label: { en: "Failed to Sync", ar: "فشلت المزامنة" } },
          { value: "success", label: { en: "Synced", ar: "تمت المزامنة" } },
        ],
      },
    },

    {
      label: { en: "E-Invoice Status", ar: "حالة الفاتورة الإلكترونية" },
      name: "e_invoice_status",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "success", label: { en: "Success", ar: "تم التقديم" } },
          { value: "failed", label: { en: "Failed", ar: "فشل التقديم" } },
          { value: "pending_submission", label: { en: "Pending", ar: "قيد التقديم" } },
          { value: "not_submitted", label: { en: "Not Submitted", ar: "لم يتم تقديمها" } },
          { value: "submitted", label: { en: "Submitted", ar: "تم التقديم" } },
          { value: "valid", label: { en: "Valid", ar: "صالحة" } },
          { value: "invalid", label: { en: "Invalid", ar: "غير صالحة" } },
          { value: "cannot_be_submitted", label: { en: "Cannot be Submitted", ar: "لا يمكن التقديم" } },
          { value: "syncing", label: { en: "Syncing", ar: "المزامنة" } },
          { value: "sent", label: { en: "Sent", ar: "تم الارسال" } },
        ],
      },
    },
  ],
});
