import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, dataEntry } = ROLES;

export default new Entity({
  name: "category",
  pluralName: "categories",
  pathName: "categories",
  endpoint: "/admin/categories",
  serverName: "category",
  serverPluralName: "categories",
  displayName: { en: "Categories", ar: "التصنيفات" },
  allowCreate: true,
  allowEdit: true,
  titleField: "name_en",
  allowDelete: true,
  showRowActions: true,
  useFormData: true,
  showSearch: true,
  entityIdentifier: "id",
  customErrorKeys: ["name"],
  customNotifications: {
    delete: {
      error: {
        title: { en: "Error", ar: "خطأ" },
        message: (dataObject) => dataObject?.error?.reduce((acc, err) => (acc += err + ",\n"), "") || null,
      },
    },
  },
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin, dataEntry],
    create: [superAdmin, dataEntry],
  },
  // import: {
  //   enabled: true,
  //   extensions: ["xlsx", "application/vnd.openxmlformats-officedocument"],
  //   paramName: "file",
  //   method: "put",
  //   endpoint: "/admin/categories/import",
  // },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "code",
      label: { en: "Code", ar: "الكود" },
      accessor: (dataObject) => dataObject.code || "-",
      formAccessor: "code",
      type: "string",
      inList: true,
      required: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "name_ar",
      type: "string",
      hideInShow: false,
      inList: true,
      required: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم الانجليزي" },
      accessor: "name_en",
      type: "string",
      hideInShow: false,
      inList: true,
      required: true,
    },
    {
      identifier: "show_on_homepage",
      label: { en: "show on home page", ar: "اظهار فى الصفحة الرئيسية" },
      accessor: "show_on_homepage",
      type: "toggle",
      toggleConfig: {
      on: true,
      off: false,
      },
      hideInShow: false,
      inList: true,
      defaultValue: false,
      },
      {
      identifier: "icon",
      label: { en: "icon", ar: "أيقونة" },
      accessor: "icon",
      type: "image",
      hideInShow: false,
      },
    // {
    //   identifier: "name",
    //   label: { en: "Name", ar: "الاسم" },
    //   accessor: "name",
    //   inList: true,
    //   hideInEdit: true,
    //   hideInCreate: true,
    //   type: "string",
    // },
    {
      identifier: "parent_id",
      label: { en: "Parent Category", ar: "التصنيف الاساسي" },
      accessor: (dataObject) => (dataObject?.parent ? dataObject.parent.name : null),
      formAccessor: (dataObject) => (dataObject?.parent ? dataObject.parent.id : null),
      inList: true,
      type: "reference",
      isSearchable: true,
      optionsEntity: {
        name: "category",
        labelField: "name",
        valueField: "id",
        filterParams: [
          {
            name: "category_id",
            value: ["params", "id"],
            type: "edit",
          },
          {
            name: "type",
            value: "parent_eligible",
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "children",
      accessor: "children",
      label: { en: "Direct Sub Categories", ar: "تصنيفات فرعية مباشرة" },
      hideInEdit: true,
      hideInCreate: true,
      type: "dynamic",
      fields: [
        {
          identifier: "name",
          accessor: "name",
          label: "Name",
          hideInCreate: true,
          hideInEdit: true,
        },
      ],
    },
  ],
});
