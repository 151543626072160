import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin } = ROLES;

export default new Entity({
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  endpoint: "/admin/admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: { en: "Admins", ar: "المدراء" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  entityIdentifier: "id",
  titleField: "name",
  authorizedRoles: {
    entity: [superAdmin],
    edit: [superAdmin],
    create: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الاكتروني" },
      accessor: "email",
      inList: true,
      type: "email",
      hideInEdit: true,
    },
    {
      identifier: "role",
      label: { en: "Role", ar: "وظيفة" },
      accessor: "role",
      inList: true,
      type: "select",
      options: [
        { value: "super_admin", label: { en: "Super Admin", ar: "ملغى" } },
        { value: "call_center", label: { en: "Call center", ar: "خدمة عملاء" } },
        { value: "operation", label: { en: "Operation", ar: "في الطريق" } },
        { value: "data_entry", label: { en: "Data Entry", ar: "مدخل بيانات" } },
        { value: "integration", label: { en: "Integration", ar: "نظام" } }
      ],

      required: true,
    },
  ],
});
