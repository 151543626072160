import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import SplitTextByNewLine from "../../components/SplitTextByNewLine";
import { ROLES } from "utils/constants";
const { superAdmin } = ROLES;

export default new Entity({
  name: "transactionVersion",
  pluralName: "transactionVersions",
  pathName: "transactionVersion",
  endpoint: "/admin/transactions/versions",
  serverName: "paper_trail/version",
  serverPluralName: "paper_trail/versions",
  displayName: { en: "Transaction Versions", ar: "معاينة التعديلات على المعاملات" },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showSearch: true,
  showRowActions: false,
  authorizedRoles: {
    entity: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "مسلسل" },
      accessor: "id",
      usedToShow: true,
    },
    {
      identifier: "item_id",
      label: { en: "Transaction id", ar: "رقم المعاملة" },
      accessor: "item_id",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "event",
      label: { en: "Event", ar: "حدث" },
      accessor: "event",
      inList: true,
    },
    {
      identifier: "whodunnit",
      label: { en: "Created/Updated by", ar: "تم إنشاؤه/تحديثه بواسطة" },
      accessor: "whodunnit",
      inList: true,
    },
    {
      identifier: "created_at_date",
      label: { en: "Created/Updated at Date", ar: "انشئ/عدل في تاريخ" },
      accessor: (dataObject) => (dataObject?.created_at ? moment(dataObject.created_at).format("lll") : null),
      inList: true,
    },
    {
      identifier: "gateway_id",
      label: { en: "gateway_id", ar: "رقم البوابه" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.gateway_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.gateway_id : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "payment_reference",
      label: { en: "Payment Reference", ar: "مرجع الدفع" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.payment_reference,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.payment_reference : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "user_id",
      label: { en: "User ID", ar: "المستخدم" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.user_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.user_id : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "amount",
      label: { en: "Amount", ar: "القيمة المالية" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.amount,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.amount : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.status,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.status : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "payment_method",
      label: { en: "Payment Method", ar: "طريقة الدفع" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.payment_method,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.payment_method : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "order_id",
      label: { en: "Order ID", ar: "رقم الطلب" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.order_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.order_id : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "is_audited",
      label: { en: "Is audited", ar: "تم التدقيق" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.is_audited,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.is_audited : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "invoice_number",
      label: { en: "Invoice number", ar: "رقم الفاتورة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.invoice_number,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.invoice_number : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "transaction_type",
      label: { en: "Transaction type", ar: "نوع العملية" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.transaction_type,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.transaction_type : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "sap_refrence_id",
      label: { en: "SAP Reference ID", ar: "الرقم التعريفي فى SAP" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.sap_refrence_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.sap_refrence_id : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
  ],

  filters: [
    {
      label: { en: "Event", ar: "حدث" },
      name: "event",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "destroy", label: { en: "Destroy", ar: "مسح" } },
          { value: "create", label: { en: "Create", ar: "إنشاء" } },
          { value: "update", label: { en: "Update", ar: "تحديث" } },
        ],
      },
    },

    {
      label: { en: "Start Date", ar: "تاريخ البدء" },
      name: "created_at_since",
      type: "date",
    },
    {
      label: { en: "End Date", ar: "تاريخ الانتهاء" },
      name: "created_at_until",
      type: "date",
    },
  ],
});
