import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin } = ROLES;

export default new Entity({
  name: "deliveryZone",
  pluralName: "delivery_zones",
  pathName: "delivery_zones",
  endpoint: "/admin/delivery_zones",
  serverName: "delivery_zone",
  serverPluralName: "delivery_zones",
  displayName: { en: "Delivery Zone", ar: "مناطق توصيل" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  titleField: "name",
  showRowActions: true,
  showSearch: true,
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      inList: true,
      type: "string",
    },
    {
      identifier: "shipping_fees",
      label: { en: "Shipping Fees", ar: "مصاريف الشحن" },
      accessor: "shipping_fees",
      type: "number",
      required: true,
    },
    {
      identifier: "coordinates_attributes",
      accessor: "coordinates",
      label: { en: "Location", ar: "موقع" },
      type: "polygonMap",
      defaultValue: [],
      hideInEdit: true,
      mapConfig: {
        callBackOnChange: (dispatch, coordinates) => {},
        zoom: 8,
        initialCenterCoordinate: { lat: 30.04943, lng: 31.23095 },
      },
    },
  ],
});
