import { isAdminRole } from "utils/authHelpers";
import moment from "moment";

const statusesSection = [
  // {
  //   identifier: "status",
  //   label: { en: "status", ar: "حالة" },
  //   accessor: "status",
  //   lgCol: 6,
  //   mdCol: 6,
  //   type: "string",
  //   inList: true,
  //   disabled: true,
  //   hideInCreate: true,
  // },
  {
    identifier: "status_event",
    label: { en: "Status event", ar: "حالة الشحنة" },
    accessor: (dataObject) => (dataObject.order_status_event ? dataObject.order_status_event.value : null),
    formAccessor: (dataObject) => (dataObject.order_status_event ? dataObject.order_status_event.value : null),
    lgCol: 12,
    mdCol: 12,
    hideInCreate: true,
    hideInShow: true,
    dependee: true,
    type: "reference",
    optionsEntity: {
      name: "orderStatusEvent",
      labelField: "label",
      valueField: "value",

      filterParams: [
        {
          name: "order_id",
          value: ["params", "id"],
          type: "edit",
        },
        {
          name: "state_column_name",
          value: "status",
          type: "edit",
        },
      ],
    },
  },
  {
    identifier: "refund_reason",
    label: { en: "Refund reason", ar: "سبب الاسترداد" },
    accessor: "refund_reason",
    type: "string",
    dependeeListOperator: "or",
    required: true,
    dependeeList: [
      {
        dependsOn: "status_event",
        dependsOnValue: ["partial_refund", "refund"],
      },
      {
        dependsOn: "status",
        dependsOnValue: ["partially_refunded", "refunded"],
      },
    ],
  },
  {
    identifier: "delivered_date",
    label: { en: "Delivered date", ar: "تاريخ التسليم" },
    inList: true,
    accessor: (dataObject) =>
      dataObject.delivered_date ? moment(dataObject.delivered_date).format("MMMM Do YYYY") : "-",
    formAccessor: "delivered_date",
    type: "datetime",
    disableFuture: true,
    dependsOn: "status_event",
    dependsOnValue: "mark_as_delivered",
    hideInEdit: (dataObject) => !isAdminRole("super_admin") && !isAdminRole("operation"),
    valuesRejectedOnSubmit: [null],
  },
  {
    identifier: "is_cash_collected",
    label: { en: "Cash collected", ar: "تم جمع النقد" },
    accessor: (dataObject) => (dataObject.is_cash_collected ? "Yes" : "No"),
    type: "string",
    hideInEdit: true,
    hideInCreate: true,
  },
];

export default statusesSection;
