import { Entity } from "@thewebops/admin-panel";

export default authConfig =>
  new Entity({
    title: "Reset Password",
    name: "resetPassword",
    subTitle: "Enter your new password",
    path: `/passwords/${authConfig.pathName}/:token`,
    actionLabel: "Reset password",
    action: "create",
    fields: [
      {
        label: "New Password",
        required: true,
        type: "password",
        identifier: "password",
      },
      {
        label: "Confirm new Password",
        required: true,
        type: "password",
        identifier: "password_confirmation",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverName}/passwords`,
      method: "put",
      successMessage: "Password has been reset",
      errorMessage: "Couldn't reset password",
      successRedirectPath: "/login",
    },
    queryParamName: "reset_password_token",
  });
