import { Entity } from "@thewebops/admin-panel";

import { ROLES } from "utils/constants";

const { superAdmin, dataEntry, operation, callCenter } = ROLES;

export default new Entity({
  name: "pageSetting",
  pluralName: "page_settings",
  pathName: "page_settings",
  endpoint: "/admin/page_settings",
  serverName: "page_setting",
  serverPluralName: "page_settings",
  displayName: { en: "Page Settings", ar: "عداد الصفحة" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  useFormData: true,
  authorizedRoles: {
    entity: [superAdmin],
    edit: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "key",
      label: "Key",
      accessor: "key",
      type: "string",
      inList: true,
      hideInCreate: false,
      hideInEdit: false,
      // disabled: true,
      required: true,
    },
    {
      identifier: "group",
      label: "Group",
      accessor: "group",
      inList: true,
      hideInCreate: false,
      hideInEdit: false,
      // disabled: true,
      required: true,
      type: "select",
      options: [
        {
          value: "home_page",
          label: "Home Page",
        },
        {
          value: "about_us",
          label: "About Us",
        },
      ],
    },
    {
      identifier: "value_type",
      label: "Value Type",
      accessor: "value_type",
      type: "select",
      dependee: true,
      inList: true,
      hideInCreate: false,
      hideInEdit: false,
      // disabled: true,
      required: true,
      options: [
        {
          value: "image",
          label: "Image",
        },
        {
          value: "text",
          label: "Text",
        },
        {
          value: "editor",
          label: "Editor",
        },
        {
          value: "file",
          label: "File",
        },
      ],
    },
    {
      identifier: "attachment",
      accessor: "attachment",
      label: "Attachment",
      type: "image",
      dependsOn: "value_type",
      dependsOnValue: "image",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
      required: true,
    },
    {
      identifier: "value_en",
      accessor: "value_en",
      label: "English Value",
      type: "text",
      dependsOn: "value_type",
      dependsOnValue: "text",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
    },

    {
      identifier: "value_ar",
      accessor: "value_ar",
      label: "Arabic Value",
      type: "text",
      dependsOn: "value_type",
      dependsOnValue: "text",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
    },
    {
      identifier: "value_en",
      accessor: "value_en",
      label: "English Value",
      type: "editor",
      dependsOn: "value_type",
      dependsOnValue: "editor",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
    },

    {
      identifier: "value_ar",
      accessor: "value_ar",
      label: "Arabic Value",
      type: "editor",
      dependsOn: "value_type",
      dependsOnValue: "editor",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
    },
    {
      identifier: "file",
      accessor: "file",
      label: "File",
      type: "file",
      dependsOn: "value_type",
      dependsOnValue: "file",
      inList: false,
      hideInCreate: false,
      hideInEdit: false,
      required: true,
    },
  ],
});
