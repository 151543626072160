import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, showNotification, filtersToAttributes, getEntity, entitiesActions } from "@thewebops/admin-panel";
import actions from "./actions";
import { saveAs } from "file-saver";
import i18n from "i18next";

function* downloadImportTemplate(action) {
  const { success, failure } = actions.downloadImportTemplate;
  try {
    const response = yield call(request, {
      url: `admin/products/download_template.xlsx`,
      method: "get",
      authenticated: true,
      isDownloading: true,
    });
    yield call(saveAs, response.data, "import_template.xlsx");
    yield put(success());
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure());
  }
}

function* archiveSaga(action) {
  const {
    entity,
    item: { id },
    data,
  } = action.payload;
  const { success, failure } = actions.archive;

  try {
    yield call(request, {
      url: `${entity.endpoint}/${id}/archive`,
      method: "put",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success");
    yield put(entitiesActions[entity.name]["list"]["request"]({ entity }));
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure({ errors: error.response.data, current: { id, ...data } }));
  }
}

function* publishSaga(action) {
  const {
    entity,
    item: { id },
    data,
  } = action.payload;
  const { success, failure } = actions.publish;

  try {
    yield call(request, {
      url: `${entity.endpoint}/${id}/publish`,
      method: "put",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success");
    yield put(entitiesActions[entity.name]["list"]["request"]({ entity }));
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure({ errors: error.response.data, current: { id, ...data } }));
  }
}

function* exportProducts(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportProducts;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    let { brand_id, category_id, featured } = {
      ...filtersToAttributes(entity, { ...storeEntity, ...action.payload }),
    };
    yield call(request, {
      url: "admin/export_sheets",
      data: {
        brand_id,
        category_id,
        featured,
        export_type: "products",
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());

    showNotification(null, "error");
  }
}

// This is a test feature and should be removed before merging to master
// TODO: Delete this function
function* destoryAllProducts(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.destoryAllProducts;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    yield call(request, {
      url: "admin/products/destroy_all",
      method: "delete",
      authenticated: true,
    });
    yield put(entitiesActions[entity.name]["list"]["request"]({ entity }));
    showNotification(null, "success");
  } catch (error) {
    yield put(failure());

    showNotification(null, "error");
  }
}

function* watchEntityDownloadImportTemplate() {
  const { request } = actions.downloadImportTemplate;
  yield takeLatest(request, downloadImportTemplate);
}

function* watchArchiveSaga() {
  const { request } = actions.archive;
  yield takeLatest(request, archiveSaga);
}
function* watchPublishSaga() {
  const { request } = actions.publish;
  yield takeLatest(request, publishSaga);
}
function* watchExportProductsSaga() {
  const { request } = actions.exportProducts;

  yield takeLatest(request, exportProducts);
}

// This is a test feature and should be removed before merging to master
// TODO: Delete this function
function* watchDestroyAllProductsSaga() {
  const { request } = actions.destoryAllProducts;

  yield takeLatest(request, destoryAllProducts);
}

export default [watchEntityDownloadImportTemplate, watchExportProductsSaga, watchArchiveSaga, watchPublishSaga, watchDestroyAllProductsSaga];
