import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, dataEntry } = ROLES;

export default new Entity({
  name: "Branch",
  pluralName: "branches",
  pathName: "branches",
  endpoint: "/admin/branches",
  serverName: "branch",
  serverPluralName: "branches",
  displayName: { en: "Branches", ar: "الفروع" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showSearch: true,
  showRowActions: true,
  titleField: "name",
  useFormData: true,
  entityIdentifier: "id",
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin, dataEntry],
    create: [superAdmin, dataEntry],
    delete: [superAdmin, dataEntry],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: {
        en: "Name",
        ar: "اسم",
      },
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
      localized: true,
      locales: [
        {
          locale: "en",
          labelSuffix: {
            en: "english",
            ar: "إنجليزي",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
        {
          locale: "ar",
          labelSuffix: {
            en: "arabic",
            ar: "عربى",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
      ],
    },
    {
      identifier: "address",
      label: {
        en: "Address",
        ar: "عنوان",
      },
      accessor: "address",
      inList: true,
      type: "string",
      required: true,
      localized: true,
      locales: [
        {
          locale: "en",
          labelSuffix: {
            en: "english",
            ar: "إنجليزي",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
        {
          locale: "ar",
          labelSuffix: {
            en: "arabic",
            ar: "عربى",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
      ],
    },
    {
      identifier: "phone_numbers_attributes",
      accessor: "phone_numbers",
      label: { en: "Branch Numbers", ar: "رقم الفرع" },
      type: "dynamic",
      lgCol: 6,
      mdCol: 6,
      fields: [
        {
          identifier: "id",
          label: { en: "ID", ar: "مسلسل" },
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "number_type",
          label: { en: "Number Type", ar: "نوع الرقم" },
          accessor: "number_type",
          type: "select",
          persistDependeeValues: true,
          options: [
            { value: "mobile", label: { en: "Mobile", ar: "محمول" } },
            { value: "home", label: { en: "Home", ar: "أرضي" } },
          ],
          required: true,
        },
        {
          identifier: "city_code",
          label: { en: "City Code", ar: "كود المحافظة" },
          accessor: "city_code",
          dependsOn: "number_type",
          dependsOnValue: "home",
          inList: true,
          required: true,
          type: "select",
          options: [
            {
              value: "013",
              label: "[013] Benha",
            },
            {
              value: "02",
              label: "[02] Cairo",
            },
            {
              value: "03",
              label: "[03] Alexandria",
            },
            {
              value: "040",
              label: "[040] Tanta",
            },
          ],
        },
        {
          identifier: "number",
          label: { en: "Number", ar: "رقم الهاتف" },
          accessor: "number",
          inList: true,
          type: "string",
        },
      ],
      required: true,
    },
    {
      identifier: "pin_point",
      accessor: "pin_point",
      label: { en: "Location", ar: "نقطة الإلتقاء" },
      type: "markerMap",
      mapConfig: {
        lngFieldIdentifier: "lng",
        latFieldIdentifier: "lat",
      },
    },
  ],
});
