import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, showNotification, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import i18n from "i18next";
import actions from "./actions";

function* exportOrders(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportOrders;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    let { status, user_id, bank_installment_id, payment_method, created_at_since, created_at_until } = {
      ...filtersToAttributes(entity, { ...storeEntity, ...action.payload }),
    };
    yield call(request, {
      url: "admin/export_sheets",
      data: {
        status,
        user_id,
        payment_method,
        created_at_since,
        bank_installment_id,
        created_at_until,
        export_type: "orders",
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());

    showNotification(null, "error");
  }
}

function* resubmitEInvoice(action) {
  const {
    entity,
    item: { id },
    data,
  } = action.payload;
  const { success, failure } = actions.resubmitEInvoice;

  try {
    yield call(request, {
      url: `${entity.endpoint}/${id}/submit_e_invoice`,
      method: "put",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "E-Invoice Submission Is In Progress");
  } catch (error) {
    showNotification(null, "Submit Error", error.response.data["error"]);
    yield put(failure());
  }
}

function* retrySAPSync(action) {
  const {
    entity,
    item: { id },
    data,
  } = action.payload;
  const { success, failure } = actions.retrySAPSync;
  try {
    yield call(request, {
      url: `${entity.endpoint}/${id}/sync_sap_order`,
      method: "put",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "SAP Sync Is In Progress");
  } catch (error) {
    showNotification(null, "SAP Sync Error", error.response.data["error"]);
    yield put(failure());
  }
}

function* watchExportOrdersSaga() {
  const { request } = actions.exportOrders;

  yield takeLatest(request, exportOrders);
}

function* watchResubmitEInvoiceSaga() {
  const { request } = actions.resubmitEInvoice;

  yield takeLatest(request, resubmitEInvoice);
}

function* watchRetrySAPSyncSaga() {
  const { request } = actions.retrySAPSync;

  yield takeLatest(request, retrySAPSync);
}

export default [watchExportOrdersSaga, watchResubmitEInvoiceSaga, watchRetrySAPSyncSaga];
