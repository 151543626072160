import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, dataEntry } = ROLES;

export default new Entity({
  name: "brand",
  pluralName: "brands",
  pathName: "brands",
  endpoint: "/admin/brands",
  serverName: "brand",
  serverPluralName: "brands",
  displayName: { en: "Brands", ar: "الماركات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  useFormData: true,
  titleField: "name_en",
  showSearch: true,
  showRowActions: true,
  entityIdentifier: "id",
  customErrorKeys: ["name"],
  customNotifications: {
    delete: {
      error: {
        title: { en: "Error", ar: "خطأ" },
        message: (dataObject) => (dataObject.error ? dataObject.error : null),
      },
    },
  },
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin, dataEntry],
    create: [superAdmin, dataEntry],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "code",
      label: { en: "Code", ar: "الكود" },
      accessor: (dataObject) => dataObject.code || "-",
      formAccessor: "code",
      type: "string",
      inList: true,
      required: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم الانجليزي" },
      accessor: "name_en",
      type: "string",
      required: true,
      hideInShow: false,
      inList: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "name_ar",
      type: "string",
      required: true,
      hideInShow: false,
      inList: true,
    },
    {
      identifier: "show_on_homepage",
      label: { en: "show on home page", ar: "اظهار فى الصفحة الرئيسية" },
      accessor: "show_on_homepage",
      type: "toggle",
      toggleConfig: {
        on: true,
        off: false,
      },
      hideInShow: false,
      inList: true,
      defaultValue: false,
    },
    {
      identifier: "icon",
      label: { en: "icon", ar: "أيقونة" },
      accessor: "icon",
      type: "image",
      hideInShow: false,
    },
    {
      identifier: "featured",
      label: { en: "Featured", ar: "متميز" },
      inList: true,
      accessor: (dataObject) => (dataObject.featured ? "Yes" : "No"),
      formAccessor: "featured",
      type: "toggle",
      toggleConfig: {
        on: true,
        off: false,
      },
    },
  ],
});
