import { loadFromLocalStorage } from "@thewebops/admin-panel";
import { authConfig } from "authEntities";

const isAdminRole = (role_type) => {
  const admin = loadFromLocalStorage(authConfig.serverName);
  const role = admin ? admin.role : null;
  return role ? role === role_type : true;
};

const currentAdmin = () => loadFromLocalStorage(authConfig.serverName);

export { isAdminRole, currentAdmin };
