import React, { Fragment } from "react";
import { Typography } from "antd";


const SplitTextByNewLine = ({ value }) => (
    <Fragment>
        {value?.split("\n").map( (message, index) =>
      <Typography component="p" key={index}> {message} </Typography>
  )}
    </Fragment>

  );

export default SplitTextByNewLine;