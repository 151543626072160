import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, showNotification, entitiesActions, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import i18n from "i18next";
import actions from "./actions";

function* exportUsers(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportUsers;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    yield call(request, {
      url: "admin/export_sheets",
      data: {
        export_type: "users",
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());
    showNotification(null, "error", JSON.stringify(error.message));
  }
}


function* watchExportUsersSaga() {
  const { request } = actions.exportUsers;

  yield takeLatest(request, exportUsers);
}

export default [watchExportUsersSaga];
