import arabicTranslations from "./ar.json";

const rtlLanguages = new Map();
rtlLanguages.set('ar');

const resources = {
  ar: arabicTranslations,
};

const availableLanguages = Object.keys(resources);

export { availableLanguages, rtlLanguages, resources };
