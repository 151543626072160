import actions from "./actions";

const reducers = {
  [actions.exportOrders.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportOrders.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportOrders.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  [actions.resubmitEInvoice.request]: (state, action) => {
    state.loading = true;
  },
  [actions.resubmitEInvoice.success]: (state, action) => {
    state.loading = false;
  },
  [actions.resubmitEInvoice.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "Something went wrong while submitting e-invoice" };
  },
  [actions.retrySAPSync.request]: (state, action) => {
    state.loading = true;
  },
  [actions.retrySAPSync.success]: (state, action) => {
    state.loading = false;
  },
  [actions.retrySAPSync.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "Something went wrong while retrying SAP sync" };
  },
};

export default reducers;
