import { createActions } from "@thewebops/admin-panel";

const actions = {
  archive: createActions(`product/archive`),
  publish: createActions(`product/publish`),
  downloadImportTemplate: createActions(`product/downloadImportTemplate`),
  exportProducts: createActions(`product/exportProducts`),
  // This is a test feature and should be removed before merging to master
  // TODO: Delete this function
  destoryAllProducts: createActions(`product/destoryAllProducts`),
};
export default actions;
