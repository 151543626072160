const dashboardConfig = {
  groups: [
    {
      endpoint: "admin/dashboard/dashboard_data",
      components: [
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: {en:"Yesterday's orders", ar: "عدد طلبات الأمس"},
              },
              body: {
                accessor: "yesterday_orders_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: {en:"Today's orders", ar: "عدد طلبات اليوم"},
              },
              body: {
                accessor: "today_orders_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: {en: "Total orders", ar: "العدد الإجمالي للطلبات"},
              },
              body: {
                accessor: "total_orders_count",
              },
            },
          },
        },

        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: {en:"Total number of users", ar: "العدد الإجمالي للمستخدمين"},
              },
              body: {
                accessor: "total_users_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: {en:"Total number of products", ar: "العدد الإجمالي للمنتجات"},
              },
              body: {
                accessor: "total_products_count",
              },
            },
          },
        },
        {
          type: "displayCard",
          dataSource: "server",
          config: {
            width: {
              desktop: "33.3%",
              mobile: "100%",
              tablet: "33.3%",
            },

            data: {
              title: {
                staticValue: {en: "Total number of refunds", ar: "العدد الإجمالي للمرتجعات"},
              },
              body: {
                accessor: "total_refunded_orders",
              },
            },
          },
        },
      ],
    },
    {
      endpoint: "admin/dashboard/dashboard_charts",
      components: [
        {
          type: "graph",
          dataSource: "server",
          config: {
            type: "pie",
            data: {
              title: {
                staticValue: { en: "Products Categories Type", ar: "نوع فئات المنتجات" },
              },
              graphData: {
                accessor: "products_per_category",
              },
            },
            height: 300,
            labelFontSize: 10,
            angleField: "products_count",
            colorField: "category_name",
            width: {
              tablet: "50%",
              desktop: "50%",
              mobile: "100%",
            },
          },
        },
      ],
    },
    {
      endpoint: "admin/dashboard/dashboard_charts",
      components: [
        {
          type: "graph",
          dataSource: "server",
          config: {
            type: "line",
            data: {
              title: {
                staticValue: { en: "Orders per day", ar: "الطلبات في اليوم" },
              },
              graphData: {
                accessor: "orders_per_day",
              },
            },
            height: 300,
            labelFontSize: 10,
            xField: "date",
            yField: "count",
            showXAxisLabel: true,
            showYAxisLabel: true,
            smooth: true,
            animation: {
              appear: {
                animation: "path-in",
                duration: 5000,
              },
            },
            width: {
              tablet: "50%",
              desktop: "50%",
              mobile: "100%",
            },
          },
        },
      ],
    },
  ],
};

export default dashboardConfig;
