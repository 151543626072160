import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "orderProduct",
  pluralName: "orderProducts",
  pathName: "order_products",
  endpoint: "/admin/order_products",
  serverName: "order_product",
  serverPluralName: "order_products",
  isPassive: true,
  displayName: "Order Products",
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: false,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "product_title",
      label: { en: "Product Name", ar: "إسم المنتج" },
      accessor: (dataObject) => dataObject?.product?.title,
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "barcode",
      label: { en: "Barcode", ar: "الكود" },
      accessor: (dataObject) => dataObject?.product?.barcode,
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "checkout_price",
      label: { en: "Checkout Price", ar: "السعر وقت الشراء" },
      inList: true,
      accessor: "checkout_price",
      type: "number",
    },
    {
      identifier: "quantity",
      label: { en: "Quantity", ar: "الكمية" },
      inList: true,
      accessor: "quantity",
      type: "number",
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      accessor: "status",
      inList: true,
      type: "select",
      hideInCreate: true,
      hideInEdit: true,
      options: [
        { value: "created", label: { en: "Created", ar: "انشئت" } },
        { value: "refunded", label: { en: "Refunded", ar: "تم الاسترجاع" } },
        { value: "partially_refunded", label: { en: "Partially refunded", ar: "مرتجع جزئياً" } },
      ],
    },
    {
      identifier: "refunded_quantity",
      label: { en: "Refund quantity", ar: "كمية الاسترداد" },
      accessor: "refunded_quantity",
      type: "number",
      inList: true,
      hideInCreate: true,
      hideInEdit: false,
    },
  ]
});
