import actions from "./actions";

const reducers = {
  [actions.downloadImportTemplate.request]: (state, action) => {
    state.loading = true;
  },
  [actions.downloadImportTemplate.success]: (state, action) => {
    state.loading = false;
  },
  [actions.downloadImportTemplate.failure]: (state, action) => {
    state.loading = false;
  },
  [actions.archive.request]: (state, action) => {
    state.loading = true;
  },
  [actions.archive.success]: (state, action) => {
    state.loading = false;
  },
  [actions.archive.failure]: (state, action) => {
    state.loading = false;
    state.current = action.payload.current;
    state.errors = action.payload.errors || { message: "there was an error" };
  },
  [actions.publish.request]: (state, action) => {
    state.loading = true;
  },
  [actions.publish.success]: (state, action) => {
    state.loading = false;
  },
  [actions.publish.failure]: (state, action) => {
    state.loading = false;
    state.current = action.payload.current;
    state.errors = action.payload.errors || { message: "there was an error" };
  },
  [actions.exportProducts.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportProducts.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportProducts.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
  // This is a test feature and should be removed before merging to master
  // TODO: Delete this function
  [actions.destoryAllProducts.request]: (state, action) => {
    state.loading = true;
  },
  [actions.destoryAllProducts.success]: (state, action) => {
    state.loading = false;
  },
  [actions.destoryAllProducts.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;
