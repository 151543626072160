import actions from "./actions";

const reducers = {
  [actions.audit.request]: (state, action) => {
    state.loading = true;
  },
  [actions.audit.success]: (state, action) => {
    state.loading = false;
  },
  [actions.audit.failure]: (state, action) => {
    state.loading = false;
    state.current = action.payload.current;
    state.errors = action.payload.errors || { message: "there was an error" };
  },
  [actions.exportTransactions.request]: (state, action) => {
    state.loading = true;
  },
  [actions.exportTransactions.success]: (state, action) => {
    state.loading = false;
  },
  [actions.exportTransactions.failure]: (state, action) => {
    state.loading = false;
    state.errors = action.payload || { message: "there was an error" };
  },
};

export default reducers;
