import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, dataEntry, operation } = ROLES;

export default new Entity({
  name: "bankInstallment",
  pluralName: "bankInstallments",
  pathName: "bank_installments",
  endpoint: "/admin/bank_installments",
  serverName: "bank_installment",
  serverPluralName: "bank_installments",
  displayName: { en: "Bank Installments", ar: "الأقساط البنكية" },
  allowCreate: true,
  allowEdit: true,
  showSearch: true,
  showRowActions: true,
  entityIdentifier: "id",
  customErrorKeys: ["title", "bank_name"],
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin],
    create: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "bank_id",
      label: { en: "Bank", ar: "البنك" },
      accessor: dataObject => dataObject?.bank?.name,
      formAccessor: dataObject => dataObject?.bank?.id,
      type: "reference",
      inList: true,
      isSearchable: true,
      dependee: true,
      optionsEntity: {
        name: "bank",
        labelField: "name",
        valueField: "id",
        filterParams: [
          {
            name: "active",
            value: "true",
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "title_en",
      label: { en: "English Title", ar: "العنوان الانجليزي" },
      accessor: "title_en",
      type: "string",
      required: true,
      inList: true,
      hideInEdit: false,
    },
    {
      identifier: "title_ar",
      label: { en: "Arabic Title", ar: "العنوان العربي" },
      accessor: "title_ar",
      type: "string",
      required: true,
      inList: true,
      hideInEdit: false,
    },
    {
      identifier: "months",
      label: { en: "Number of months", ar: "عدد الشهور" },
      accessor: "months",
      type: "number",
      required: true,
      inList: true,
      hideInEdit: false,
    },
    {
      identifier: "active",
      label: { en: "Active", ar: "فعال" },
      inList: true,
      accessor: dataObject => (dataObject.active ? "Yes" : "No"),
      formAccessor: dataObject => (dataObject.active ? "true" : "false"),
      type: "radio",
      options: [
        { value: "true", label: { en: "Yes", ar: "Yes" } },
        { value: "false", label: { en: "No", ar: "No" } },
      ],
      required: true,
    },
    {
      identifier: "old_products",
      label: { en: "Selected Products", ar: "المنتجات المختارة" },
      lgCol: 9,
      mdCol: 9,
      useObjectArray: true,
      accessor: dataObject => (dataObject.products ? dataObject.products : []),
      type: "reference",
      multiple: true,
      isSearchable: true,
      required: false,
      optionsEntity: {
        name: "product",
        labelField: "title",
        valueField: "id",
        valueLabel: "id",
        filterParams: [
          {
            name: "none",
            value: "true",
            type: "edit",
          },
        ],
      },
      hideInCreate: true,
    },
    {
      identifier: "category_id",
      label: { en: "Category", ar: "التصنيف" },
      multiple: true,
      type: "reference",
      isSearchable: true,
      optionsEntity: {
        name: "category",
        labelField: "name",
        valueField: "id",
        filterParams: [
          {
            name: "type",
            value: "child",
            type: "both",
          },
        ],
      },
      hideInShow: true,
    },
    {
      identifier: "brand_id",
      label: { en: "Brand", ar: "الماركة" },
      multiple: true,
      type: "reference",
      isSearchable: true,
      optionsEntity: {
        name: "brand",
        labelField: "name",
        valueField: "id",
      },
      hideInShow: true,
    },
    {
      identifier: "select_all_products",
      label: { en: "Select all products", ar: "تم التدقيق" },
      accessor: () => false,
      type: "toggle",
      toggleConfig: {
        on: true,
        off: false,
      },
      dependee: true,
      defaultValue: true,
      hideInShow: true,
    },
    {
      identifier: "product_ids",
      label: { en: "New Products", ar: "منتجات جديدة" },
      lgCol: 9,
      mdCol: 9,
      type: "reference",
      multiple: true,
      hideInShow: true,
      isSearchable: true,
      dependsOn: "select_all_products",
      dependsOnValue: false,
      persistDependeeValues: true,
      optionsEntity: {
        name: "product",
        labelField: "title",
        valueField: "id",
        filterParams: [
          {
            name: "category_id",
            value: ["state", "category_id"],
            type: "both",
          },
          {
            name: "brand_id",
            value: ["state", "brand_id"],
            type: "both",
          },
        ],
      },
    },
  ],
  filters: [
    {
      label: { en: "Active", ar: "فعال" },
      name: "is_active",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: { en: "Yes", ar: "Yes" }, value: true },
          { label: { en: "No", ar: "No" }, value: false },
        ],
      },
    },
  ],
});
