import admin from "./Admin";
import user from "./User";
import category from "./Category";
import brand from "./Brand";
import order from "./Order";
import address from "./Address";
import product from "./Product";
import deliveryZone from "./DeliveryZone";
import transaction from "./Transaction";
import productVersion from "./Product/Version";
import orderVersion from "./Order/Version";
import Branch from "./Branch";
import pageSetting from "./PageSetting";
import orderStatusEvent from "./Order/Event";
import exportSheet from "./ExportSheet";
import bankInstallment from "./BankInstallment";
import productivityReport from "./ProductivityReport";
import promotion from "./Promotion";
import orderProduct from "./OrderProduct";
import bank from "./Bank";
import transactionVersion from "./Transaction/Version";

const userDefinedEntities = {
  admin,
  user,
  category,
  brand,
  order,
  address,
  product,
  deliveryZone,
  transaction,
  productVersion,
  orderVersion,
  orderStatusEvent,
  pageSetting,
  Branch,
  exportSheet,
  bankInstallment,
  productivityReport,
  promotion,
  orderProduct,
  bank,
  transactionVersion,
};

const groups = [
  {
    name: "ungrouped",
    label: "General",
    type: "normal",
    entities: [
      admin,
      user,
      category,
      brand,
      address,
      deliveryZone,
      pageSetting,
      Branch,
      exportSheet,
      productivityReport,
    ],
  },
  {
    name: { en: "Products", ar: "منتجات" },
    entities: [product, productVersion, promotion],
  },
  {
    name: { en: "Transactions", ar: "منتجات" },
    entities: [transaction, transactionVersion],
  },
  {
    name: { en: "Orders", ar: "طلبات" },
    entities: [order, orderVersion],
  },
  {
    name: { en: "Banks", ar: "البنوك" },
    entities: [bank, bankInstallment],
  },
];
export { userDefinedEntities, groups };
