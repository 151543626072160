import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, dataEntry } = ROLES;

export default new Entity({
  name: "bank",
  pluralName: "banks",
  pathName: "banks",
  endpoint: "/admin/banks",
  serverName: "bank",
  serverPluralName: "banks",
  displayName: { en: "Banks", ar: "البنوك" },
  allowCreate: true,
  allowEdit: true,
  titleField: "name_en",
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  entityIdentifier: "id",
  customErrorKeys: ["name"],
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin, dataEntry],
    create: [superAdmin, dataEntry],
    delete: [superAdmin, dataEntry],
  },
  customNotifications: {
    delete: {
      error: {
        title: { en: "Error", ar: "خطأ" },
        message: dataObject => (dataObject.error ? dataObject.error : null),
      },
    },
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name_ar",
      label: { en: "Arabic Name", ar: "الاسم العربي" },
      accessor: "name_ar",
      type: "string",
      hideInShow: false,
      inList: true,
      required: true,
    },
    {
      identifier: "name_en",
      label: { en: "English Name", ar: "الاسم الانجليزي" },
      accessor: "name_en",
      type: "string",
      hideInShow: false,
      inList: true,
      required: true,
    },
    {
      identifier: "active",
      label: { en: "Active", ar: "فعال" },
      inList: true,
      accessor: dataObject => (dataObject.active ? "Yes" : "No"),
      formAccessor: dataObject => (dataObject.active ? "true" : "false"),
      type: "radio",
      options: [
        { value: "true", label: { en: "Yes", ar: "Yes" } },
        { value: "false", label: { en: "No", ar: "No" } },
      ],
    },
  ],
});
