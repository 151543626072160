import { Entity } from "@thewebops/admin-panel";
import moment from "moment";
import SplitTextByNewLine from "../../components/SplitTextByNewLine";
import { ROLES } from "utils/constants";
const { superAdmin } = ROLES;

export default new Entity({
  name: "productVersion",
  pluralName: "productVersions",
  pathName: "productVersion",
  endpoint: "/admin/products/versions",
  serverName: "paper_trail/version",
  serverPluralName: "paper_trail/versions",
  displayName: { en: "Product Versions", ar: "معاينة التعديلات على المنتج" },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  authorizedRoles: {
    entity: [superAdmin],
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "مسلسل" },
      accessor: "id",
      usedToShow: true,
    },
    {
      identifier: "item_id",
      label: { en: "Product id", ar: "رقم المنتج" },
      accessor: "item_id",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "item_name",
      label: { en: "Product Name", ar: "إسم المنتج" },
      accessor: "item_name",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "event",
      label: { en: "Event", ar: "الحالة" },
      accessor: "event",
      inList: true,
    },
    {
      identifier: "whodunnit",
      label: { en: "Created/Updated by", ar: "تم إنشاؤه/تحديثه بواسطة" },
      accessor: "whodunnit",
      inList: true,
    },
    {
      identifier: "created_at_date",
      label: { en: "Created/Updated at Date", ar: "انشئ/عدل في تاريخ" },
      accessor: (dataObject) => (dataObject?.created_at ? moment(dataObject.created_at).format("lll") : null),
      inList: true,
    },
    {
      identifier: "barcode",
      label: { en: "Barcode", ar: "باركود" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.barcode,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.barcode : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "title_en",
      label: { en: "Title English", ar: "إسم بالانجليزي" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.title_en,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.title_en : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "title_ar",
      label: { en: "Title Arabic", ar: "إسم بالعربي" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.title_ar,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.title_ar : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "description_en",
      label: { en: "Description English", ar: "وصف بالإنجليزي" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.description_en,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.description_en : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "description_ar",
      label: { en: "Description Arabic", ar: "وصف بالعربي" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.description_ar,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.description_ar : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "brand_id",
      label: { en: "Brand ID", ar: "معرف العلامة التجارية" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.brand_name,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.brand_name : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "category_id",
      label: { en: "Category ID", ar: "معرف الفئة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.category_name,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.category_name : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "price",
      label: { en: "Price", ar: "السعر" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.price,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.price : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "tax_percentage",
      label: { en: "Tax Percentage", ar: "نسبة الضرائب" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.tax_percentage,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.tax_percentage : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "unit_of_measure",
      label: { en: "Unit of Measure", ar: "وحدة القياس" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.unit_of_measure,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.unit_of_measure : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "warranty_price",
      label: { en: "Warranty Price", ar: "سعر الضمان" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.warranty_price,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.warranty_price : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "maximum_number_per_order",
      label: { en: "Maximum Number Per Order", ar: "الحد الأقصى لكل طلب" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.maximum_number_per_order,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.maximum_number_per_order : null),
      inList: false,
      type: "number",
      component: SplitTextByNewLine,
    },
    {
      identifier: "article_code_number",
      label: { en: "Article Code Number", ar: "رقم رمز المقالة" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.article_code_number,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.article_code_number : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "featured",
      label: { en: "Featured", ar: "متميز" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.featured,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.featured : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "promotion_id",
      label: { en: "Promotion ID", ar: "معرف الترويج" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.promotion_id,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.promotion_id : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "discarded_at",
      label: { en: "Discarded At", ar: "تم التجاهل في" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.discarded_at,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.discarded_at : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "percentage",
      label: { en: "Promotion Percentage", ar: "النسبة المئوية" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.percentage,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.percentage : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
    {
      identifier: "gs1_code",
      label: { en: "GS1 Code", ar: "كود GS1" },
      hideInShow: (dataObject) => dataObject?.changeset && !dataObject.changeset?.gs1_code,
      accessor: (dataObject) => (dataObject.changeset ? dataObject.changeset?.gs1_code : null),
      inList: false,
      type: "string",
      component: SplitTextByNewLine,
    },
  ],
});
