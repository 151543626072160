const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || "development") {
    case "development":
      return "http://localhost:3000/v1";
    case "staging":
      return "https://abdelaziz-stores-api.thewebops.com/v1";
    case "stg-dev":
      return "https://dev-abdelaziz-stores-api.thewebops.com/v1";
    case "production":
      return "https://api.abdulaziz-stores.com/v1";
    default:
      return "APP_API_URL";
  }
};

export const API_BASE_URL = getBaseUrl();
export const ROLES = {
  superAdmin: "super_admin",
  operation: "operation",
  callCenter: "call_center",
  dataEntry: "data_entry",
};
