import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";
import actions from "./redux/actions";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import moment from "moment";
const { superAdmin } = ROLES;

export default new Entity({
  name: "user",
  pluralName: "users",
  pathName: "users",
  endpoint: "/admin/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: { en: "Users", ar: "المستخدمين" },
  allowDelete: true,
  showRowActions: true,
  titleField: "first_name",
  showSearch: true,
  entityIdentifier: "id",
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  customNotifications: {
    delete: {
      error: {
        title: { en: "Error", ar: "خطأ" },
        message: dataObject => (dataObject.error ? dataObject.error : null),
      },
    },
  },
  authorizedRoles: {
    entity: [superAdmin],
    edit: [superAdmin],
    create: [superAdmin],
  },
  globalActions: [
    {
      label: "Export",
      name: "exportUsers",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: false,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "full_name",
      label: { en: "Full Name", ar: "الاسم" },
      accessor: "full_name",
      usedToShow: true,
      inList: true,
      type: "string",
    },
    {
      identifier: "email",
      label: { en: "Email", ar: "البريد الاكتروني" },
      accessor: "email",
      inList: true,
      type: "email",
      hideInEdit: true,
    },
    {
      identifier: "phone_number",
      label: { en: "Phone Number", ar: "رقم الهاتف" },
      accessor: "phone_number",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "national_id",
      label: { en: "National ID", ar: "الرقم القومي" },
      accessor: "national_id",
      inList: true,
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "created_at",
      label: { en: "Signed up date", ar: "تاريخ التسجيل" },
      accessor: dataObject => moment(dataObject.created_at).format("DD-MM-YYYY hh:mm A"),
      inList: true,
      type: "string",
      hideInEdit: true,
      hideInCreate: true,
    },
  ],
});
