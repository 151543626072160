import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin } = ROLES;

export default new Entity({
  name: "productivityReport",
  pluralName: "productivityReports",
  pathName: "productivity_reports",
  endpoint: "/admin/productivity_reports",
  serverName: "productivity_report",
  serverPluralName: "productivity_reports",
  displayName: { en: "Productivity Report", ar: "مناطق توصيل" },
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: false,
  authorizedRoles: {
    entity: [superAdmin]
  },
  fields: [
    {
      identifier: "admin",
      label: { en: "Admin", ar: "المسؤول" },
      accessor: "admin",
      inList: true,
    },
    {
      identifier: "count",
      label: { en: "Count", ar: "العدد" },
      accessor: "count",
      inList: true,
      type: "number",
    },
  ],
  filters: [
    {
      label: { en: "Start Time", ar: "منذ" },
      name: "created_at_since",
      type: "date",
    },
    {
      label: { en: "End Time", ar: "حتى" },
      name: "created_at_until",
      type: "date",
    },
  ],
});
