import { takeLatest, call, put, select } from "redux-saga/effects";
import { request, showNotification, entitiesActions, filtersToAttributes, getEntity } from "@thewebops/admin-panel";
import i18n from "i18next";
import actions from "./actions";

function* auditSaga(action) {
  const {
    entity,
    item: { id },
    data,
  } = action.payload;
  const { success, failure } = actions.audit;

  try {
    const response = yield call(request, {
      url: `${entity.endpoint}/${id}`,
      method: "put",
      authenticated: true,
      data: { transaction: { is_audited: true } },
    });
    yield put(success(response.data[entity.serverName]));
    showNotification(null, "success");
    yield put(entitiesActions[entity.name]["list"]["request"]({ entity }));
  } catch (error) {
    showNotification(null, "error", JSON.stringify(error.message));
    yield put(failure({ errors: error.response.data, current: { id, ...data } }));
  }
}

function* exportTransactions(action, state) {
  const { entity, customEntityName } = action.payload;
  const { success, failure } = actions.exportTransactions;
  try {
    const storeEntity = yield select(getEntity, customEntityName || entity.pluralName);

    let { status, payment_method, created_at_since, created_at_until } = {
      ...filtersToAttributes(entity, { ...storeEntity, ...action.payload }),
    };
    yield call(request, {
      url: "admin/export_sheets",
      data: {
        status,
        payment_method,
        created_at_since,
        created_at_until,
        export_type: "transactions",
      },
      method: "post",
      authenticated: true,
    });
    yield put(success());
    showNotification(null, "success", i18n.t("notifications:export"));
  } catch (error) {
    yield put(failure());

    showNotification(null, "error");
  }
}

function* watchAuditSaga() {
  const { request } = actions.audit;
  yield takeLatest(request, auditSaga);
}

function* watchExportTransactionsSaga() {
  const { request } = actions.exportTransactions;

  yield takeLatest(request, exportTransactions);
}

export default [watchAuditSaga, watchExportTransactionsSaga];
