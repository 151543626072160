import { Entity } from "@thewebops/admin-panel";
import { push } from "connected-react-router";
import { ROLES } from "utils/constants";
import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";

const { superAdmin, dataEntry } = ROLES;

export default new Entity({
  name: "product",
  pluralName: "products",
  pathName: "products",
  endpoint: "/admin/products",
  serverName: "product",
  serverPluralName: "products",
  displayName: { en: "Products", ar: "المنتجات" },
  allowCreate: false,
  allowEdit: (dataObject) => !dataObject.discarded_at,
  allowDelete: (dataObject) => dataObject.deletable,
  showRowActions: true,
  showSearch: true,
  useFormData: true,
  entityIdentifier: "id",
  customErrorKeys: ["index"],
  showFiltersInDrawer: true,
  customActions: actions,
  customSagas: sagas,
  customReducers: reducers,
  titleField: "title_en",
  customNotifications: {
    delete: {
      error: {
        title: { en: "Error", ar: "خطأ" },
        message: (dataObject) => dataObject?.error?.reduce((acc, err) => (acc += err + ",\n"), "") || null,
      },
    },
  },
  globalActions: [
    {
      label: { en: "History", ar: "التاريخ" },
      name: "showVersions",
      customFunction: (dispatch, item) => {
        dispatch(push("productVersion"));
      },
    },
    {
      label: "Export",
      name: "exportProducts",
    },
    // {
    //   // This is a test feature and should be removed before merging to master
    //   // TODO: Delete this function
    //   label: { en: "Delete all products", ar: "إلغاء كل المنتجات" },
    //   name: "destoryAllProducts",
    //   requireConfirmation: true,
    //   confirmationMessage: "Are you sure you want to delete all product? this action is irreversible"
    // },
  ],
  rowActions: [
    {
      label: { en: "Archive", ar: "أرشيف" },
      name: "archive",
      requireConfirmation: true,
      active: (dataObject) => !dataObject.deletable && !dataObject.discarded_at,
      confirmationMessage: "Are you sure you want to archive this product?",
    },
    {
      label: { en: "Publish", ar: "أنشر " },
      name: "publish",
      requireConfirmation: true,
      active: (dataObject) => dataObject.discarded_at,
      confirmationMessage: "Are you sure you want to publish this product?",
    },
  ],
  authorizedRoles: {
    entity: [superAdmin, dataEntry],
    edit: [superAdmin, dataEntry],
    create: [superAdmin, dataEntry],
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "barcode",
      label: { en: "Barcode", ar: "الكود" },
      accessor: "barcode",
      inList: true,
      type: "string",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "article_code_number",
      label: { en: "Article code number", ar: "Article code number" },
      accessor: (dataObject) => (dataObject.article_code_number ? dataObject.article_code_number : "-"),
      formAccessor: "article_code_number",
      type: "string",
      required: true,
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "title_ar",
      label: { en: "Arabic Title", ar: "العنوان العربي" },
      accessor: "title_ar",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "title_en",
      label: { en: "English Title", ar: "العنوان الانجليزي" },
      accessor: "title_en",
      inList: false,
      type: "string",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "description_ar",
      label: { en: "Arabic Description", ar: "الوصف العربي" },
      accessor: "description_ar",
      inList: false,
      type: "string",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "description_en",
      label: { en: "English Description", ar: "الوصف الانجليزي" },
      accessor: "description_en",
      inList: false,
      type: "string",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "price",
      label: { en: "Price", ar: "السعر" },
      accessor: "price",
      type: "number",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "price_after_promotion",
      label: { en: "Current Price", ar: "السعر الحالي" },
      accessor: "price_after_promotion",
      type: "number",
      required: false,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "stock",
      label: { en: "Stock", ar: "الكميه المتاحه" },
      accessor: "stock",
      type: "number",
      required: true,
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "tax_percentage",
      label: { en: "Tax percentage", ar: "نسبة الضرائب" },
      accessor: "tax_percentage",
      type: "number",
      required: true,
      hideInEdit: true,
    },
    {
      identifier: "warranty_price",
      label: { en: "Warranty price", ar: "سعر الضمان" },
      accessor: "warranty_price",
      type: "number",
      // required: true,
      // inList: true,
      hideInEdit: true,
    },
    {
      identifier: "maximum_number_per_order",
      label: { en: "Maximum number per order", ar: "الحد الأقصى لكل طلب" },
      accessor: "maximum_number_per_order",
      type: "number",
      // required: true,
      // inList: true,
      hideInEdit: true,
    },
    {
      identifier: "unit_of_measure",
      label: { en: "unit of measure", ar: "وحدة القياس" },
      accessor: "unit_of_measure",
      type: "string",
      required: true,
      // inList: true,
      hideInEdit: true,
    },
    {
      identifier: "featured",
      label: { en: "Featured", ar: "متميز" },
      accessor: (dataObject) => (dataObject?.featured ? "Yes" : "No"),
      formAccessor: "featured",
      inList: true,
      required: true,
      type: "select",
      options: [
        { value: true, label: { en: "YES", ar: "نعم" } },
        { value: false, label: { en: "NO", ar: "لا" } },
      ],
    },
    {
      identifier: "brand_id",
      label: { en: "Brand", ar: "الماركة" },
      accessor: (dataObject) => (dataObject?.brand ? dataObject.brand.name : null),
      formAccessor: (dataObject) => (dataObject?.brand ? dataObject.brand.id : null),
      inList: true,
      type: "reference",
      isSearchable: true,
      required: true,
      hideInEdit: true,
      optionsEntity: {
        name: "brand",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "category_id",
      label: { en: "Category", ar: "التصنيف" },
      required: true,
      accessor: (dataObject) => (dataObject?.category ? dataObject.category.name : null),
      formAccessor: (dataObject) => (dataObject?.category ? dataObject.category.id : null),
      inList: true,
      type: "reference",
      isSearchable: true,
      hideInEdit: true,
      optionsEntity: {
        name: "category",
        labelField: "name",
        valueField: "id",
        filterParams: [
          {
            name: "type",
            value: "child",
            type: "both",
          },
        ],
      },
    },
    {
      identifier: "promotion_id",
      label: { en: "promotion", ar: "ترويج" },
      accessor: (dataObject) => (dataObject?.promotion ? dataObject.promotion.name : null),
      formAccessor: (dataObject) => (dataObject?.promotion ? dataObject.promotion.id : null),
      type: "reference",
      isSearchable: true,
      dependee: true,
      optionsEntity: {
        name: "promotion",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "percentage",
      label: { en: "Promotion Percentage", ar: "النسبة المئوية" },
      inList: true,
      accessor: "percentage",
      dependsOn: "promotion_id",
      dependsOnAny: true,
      defaultValue: 0,
      type: "number",
      required: true,
    },
    {
      identifier: "specifications_attributes",
      accessor: "specifications",
      label: { en: "Specifications", ar: "مواصفات" },
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: { en: "ID", ar: "مسلسل" },
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "key_en",
          accessor: "key_en",
          type: "string",
          label: { en: "English Key", ar: "المفتاح الانجليزي" },
        },
        {
          identifier: "key_ar",
          accessor: "key_ar",
          type: "string",
          label: { en: "Arabic Key", ar: "المفتاح العربي" },
        },
        {
          identifier: "value_en",
          accessor: "value_en",
          type: "string",
          label: { en: "English Value", ar: "القيمة الانجليزي" },
        },
        {
          identifier: "value_ar",
          accessor: "value_ar",
          type: "string",
          label: { en: "Arabic Value", ar: "القيمة العربي" },
        },
      ],
    },
    {
      identifier: "product_images_attributes",
      accessor: "images",
      label: { en: "Images", ar: "الصور" },
      type: "dynamic",
      fields: [
        {
          identifier: "id",
          label: { en: "ID", ar: "مسلسل" },
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "photo",
          accessor: "photo",
          type: "image",
          label: { en: "photo", ar: "الصورة" },
        },
        {
          identifier: "index",
          accessor: "index",
          type: "number",
          label: { en: "Index", ar: "الترتيب" },
        },
      ],
    },
    {
      identifier: "gs1_code",
      label: { en: "GS1 Code", ar: "كود GS1" },
      accessor: "gs1_code",
      type: "string",
      required: true,
      inList: true,
      hideInEdit: true,
    },
  ],
  filters: [
    {
      label: { en: "Category", ar: "التصنيف" },
      name: "category_id",
      type: "select",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "category",
          labelField: "name",
          valueField: "id",
          filterParams: [
            {
              name: "type",
              value: "child",
              type: "both",
            },
          ],
        },
      },
    },
    {
      label: { en: "Promotion", ar: "ترويج" },
      name: "promotion_id",
      type: "select",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "promotion",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Brand", ar: "الماركة" },
      name: "brand_id",
      type: "select",
      selectConfig: {
        isSearchable: true,
        optionsType: "dynamic",
        optionsEntity: {
          name: "brand",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: { en: "Featured", ar: "متميز" },
      name: "featured",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
    },
    {
      label: { en: "Archived", ar: "مؤرشف" },
      name: "archived",
      type: "toggle",
      toggleConfig: {
        on: "true",
        off: "false",
      },
    },
  ],
});
