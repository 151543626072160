import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";
import actions from "./redux/actions";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import moment from "moment";
import { formatNumber } from "utils/globalHelpers";
const { superAdmin } = ROLES;

export default new Entity({
  name: "transaction",
  pluralName: "transactions",
  pathName: "transactions",
  endpoint: "/admin/transactions",
  serverName: "transaction",
  showFiltersInDrawer: true,
  serverPluralName: "transactions",
  displayName: { en: "Transactions", ar: "العمليات" },
  allowCreate: false,
  allowEdit: true,
  allowDelete: false,
  showRowActions: true,
  showSearch: false,
  entityIdentifier: "id",
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  authorizedRoles: {
    entity: [superAdmin],
    edit: [superAdmin],
    create: [superAdmin],
  },
  rowActions: [
    {
      label: { en: "Audit", ar: "تدقيق" },
      name: "audit",
      requireConfirmation: true,
      confirmationMessage: "Are you sure you want to audit this transaction?",
      active: dataObject => !dataObject.is_audited,
    },
  ],
  globalActions: [
    {
      label: "Export",
      name: "exportTransactions",
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
    },
    {
      identifier: "order_id",
      label: { en: "Order ID", ar: "رقم الطلب" },
      accessor: dataObject => (dataObject.order ? dataObject.order.id : "-"),
      formAccessor: dataObject => (dataObject.order ? dataObject.order.id : null),
      type: "reference",
      hideInEdit: true,
      inList: true,
      optionsEntity: {
        name: "order",
        labelField: "name",
        valueField: "id",
        displayAsLink: true,
      },
    },
    {
      identifier: "amount",
      label: { en: "Amount", ar: "القيمة المالية" },
      inList: true,
      accessor: "amount",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "payment_method",
      label: { en: "Payment Method", ar: "طريقة الدفع" },
      inList: true,
      accessor: "payment_method",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "status",
      label: { en: "Status", ar: "الحالة" },
      inList: true,
      accessor: "status",
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "user",
      label: { en: "User", ar: "المستخدم" },
      inList: true,
      accessor: dataObject => (dataObject?.user ? dataObject.user.full_name : null),
      type: "string",
      hideInEdit: true,
    },
    {
      identifier: "transaction_type",
      label: { en: "Transaction type", ar: "نوع العملية" },
      accessor: "transaction_type",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "is_audited",
      label: { en: "Is audited", ar: "تم التدقيق" },
      accessor: dataObject => (dataObject.is_audited ? "Yes" : "No"),
      formAccessor: dataObject => (dataObject.is_audited ? "true" : "false"),
      disabled: (_, dataObject) => dataObject.is_audited,
      inList: true,
      type: "toggle",
      toggleConfig: {
        on: true,
        off: false,
      },
    },
    {
      identifier: "invoice_number",
      label: { en: "Invoice number", ar: "رقم الفاتورة" },
      inList: true,
      accessor: dataObject => dataObject?.invoice_number || "-",
      formAccessor: "invoice_number",
      type: "string",
    },
    {
      identifier: "sap_refrence_id",
      label: { en: "SAP Reference ID", ar: "الرقم التعريفي فى SAP" },
      accessor: dataObject => dataObject?.sap_refrence_id || "-",
      type: "string",
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "delivered_date",
      label: { en: "Order Delivered date", ar: "تاريخ التسليم" },
      accessor: dataObject =>
        dataObject.order?.delivered_date ? moment(dataObject.order.delivered_date).format("MMMM Do YYYY") : "-",
      inList: true,
      hideInEdit: true,
    },
    {
      identifier: "created_at_date",
      label: { en: "Created at Date", ar: "تاريخ الإنشاء" },
      accessor: dataObject => (dataObject.created_at ? moment(dataObject.created_at).format("MMMM Do YYYY") : null),
      inList: true,
    },
    {
      identifier: "created_at_time",
      label: { en: "Created at Time", ar: "وقت الإنشاء" },
      accessor: dataObject => (dataObject.created_at ? moment(dataObject.created_at).format("h:mma") : null),
      inList: true,
    },
  ],
  filters: [
    {
      label: { en: "Status", ar: "الحالة" },
      name: "status",
      type: "select",
      multiple: true,
      selectConfig: {
        defaultValue: ["completed", "reflected"],
        optionsType: "static",
        optionsArray: [
          { value: "pending", label: { en: "Pending", ar: "قيد الانتظار" } },
          { value: "completed", label: { en: "Completed", ar: "قبلت" } },
          { value: "reflected", label: { en: "Reflected", ar: "ناجحة" } },
          { value: "failed", label: { en: "Failed", ar: "لم يتم القبول" } },
        ],
      },
    },
    {
      label: { en: "Payment method", ar: "طريقة الدفع" },
      name: "payment_method",
      type: "select",
      multiple: true,
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "cash", label: { en: "Cash", ar: "كاش" } },
          { value: "online", label: { en: "Online", ar: "اونلاين" } },
          { value: "visa_on_delivery", label: { en: "Card on delivery", ar: "كارت عند الاستلام" } },
          {
            value: "visa_on_delivery_installments",
            label: { en: "Card on delivery - installments", ar: "كارت عند الاستلام - تقسيط" },
          },
        ],
      },
    },
    {
      label: { en: "Start Time", ar: "منذ" },
      name: "created_at_since",
      type: "date",
    },
    {
      label: { en: "End Time", ar: "حتى" },
      name: "created_at_until",
      type: "date",
    },
    {
      label: { en: "Type", ar: "النوع" },
      name: "type",
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "debit", label: { en: "Debit", ar: "مدين" } },
          { value: "credit", label: { en: "Credit", ar: "ائتمان" } },
        ],
      },
    },
  ],
  infoDataList: [
    {
      components: [
        {
          dataSource: "redux",
          config: {
            width: {
              desktop: "100%",
              mobile: "100%",
              tablet: "100%",
            },
            data: {
              title: {
                staticValue: { en: "Total transactions amount", ar: "إجمالي مبلغ المعاملات" },
              },
              body: {
                accessor: dataObject => formatNumber(dataObject.transactions_amount),
              },
            },
          },
        },
      ],
    },
  ],
});
